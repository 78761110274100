.header {
  display: block;
  max-width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.grey-color {
  color: #868e96;
}

.header li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  cursor: pointer;
}

.header .logo {
  display: block;
  float: left;
  font-size: 1.5em;
  text-decoration: none;
  margin-top: 10px;
  line-height: normal;
}

.header .logo-name {
  font-family: "Agustina Regular";
  font-weight: bold;
  padding: 0 10px;
}

/* menu */

.menu {
  font-family: "Google Sans Regular";
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 50px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.submenu li {
  float: none;
}

/* style for submenu link */
.submenu a {
  padding: 15px 20px;
  text-decoration: none;
}

/* show submenu on hover */
.header .menu li.submenu-item:hover > .submenu {
  display: block;
}

/* adjust spacing for submenu items */
.header .menu li.submenu-item:hover > .submenu li {
  clear: left;
  width: 100%;
}

/* additional styling for Services link */
.header .menu li.submenu-item a {
  color: #868e96; /* change color as needed */
}

.header .menu li.submenu-item a:hover {
  background-color: #a6e1fa; /* change background color on hover as needed */
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}

.zoomed {
  transform: scale(0.9); /* You can adjust the scale factor */
  transition: transform 0.3s ease; /* Adjust the transition duration as needed */
}

/* CSS for zoomed image container */
.zoom-container {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  z-index: 1000; /* Ensure it appears above other content */
  transition: opacity 0.3s ease;
}

.zoomed-image {
  display: block;
  max-width: 80%;
  max-height: 80%;
  margin: auto;
  border: 2px solid white; /* Adjust as needed */
  border-radius: 5px;
}

@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px;
  }
}
